.srno-column {
  width: auto; /* Allow the column to take only the space required for its content */
  text-align: center; /* Center-align the text for better aesthetics */
  padding: 0; /* Remove padding to avoid extra space */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}

.srno-column,
.name-column,
.father-name-column,
.amount-column,
.address-column {
  text-align: center; /* Center-align the text for better aesthetics */
}
