.contact-us-page {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}

.contact-us-page h2 {
  margin-bottom: 20px;
}

.contact-us-page .form-control {
  margin-bottom: 10px;
}

.contact-us-page .btn {
  margin-top: 10px;
}
