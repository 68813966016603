.scroll-guide-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  display: none; /* Hide by default */
}

.popup-content {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  position: relative;
  max-width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-text {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
}

.animation-wrapper {
  position: relative;
  width: 100%;
  height: 100px;
  overflow: hidden;
  margin-bottom: 50px; /* Space between animation and close button */
}

.scroll-animation {
  position: absolute;
  top: 50%;
  right: -100px; /* Start position (off-screen right) */
  transform: translateY(-50%);
  width: 60px; /* Adjust size as needed */
  animation: scrollAnimation 4s infinite linear;
}

@keyframes scrollAnimation {
  0% {
    right: -100px; /* Start position (off-screen right) */
  }
  100% {
    right: calc(100% - 60px); /* End position (some distance from the left) */
  }
}

.close-btn {
  background: #ff671f;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  bottom: 20px;
  /* Center the button horizontally */
  transform: translateX(-50%);
  left: 50%;
}

.close-btn:hover {
  background: #0056b3;
}

/* Show the popup only on screens smaller than 768px */
@media (max-width: 768px) {
  .scroll-guide-popup {
    display: flex; /* Show on mobile devices */
  }
}
