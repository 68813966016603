/* src/components/NotFound.css */

.page-container-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0; /* Match the background color of your site */
}

.not-found-content {
  text-align: center;
}

.not-found-heading {
  font-size: 3rem;
  color: #ff671f; /* Match the primary color of your site */
}

.not-found-message {
  font-size: 1.5rem;
  color: #046a38; /* Match the secondary color of your site */
}

.not-found-animation {
  position: relative;
  margin-top: 20px;
}

.magnifying-glass {
  width: 50px;
  height: 50px;
  color: #046a38; /* Match the icon color */
  animation: search 2s ease-in-out infinite; /* Search animation */
}

@keyframes search {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
