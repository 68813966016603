.donation-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media (max-width: 576px) {
  .donation-card {
    margin-bottom: 20px; /* Custom spacing for extra control */
  }
}

.card-body {
  flex: 1; /* Makes sure the body takes up available space */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space out elements */
}

.card-header {
  background-color: #007bff;
  color: #fff;
}

.card-body .btn {
  margin-top: 10px;
}

.btn-primary {
  background-color: #ff671f !important;
}

.btn-success {
  background-color: #046a38 !important;
}

/* General Styling for the page */
.page-container {
  padding: 20px 0;
}

.bhamasha-heading {
  font-size: 2.5rem;
  color: #003366;
  font-weight: 700;
  margin-bottom: 10px;
}

.bhamasha-subtext {
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 100;
  color: #666;
  margin-bottom: 30px;
}

/* Table styling */
table {
  background-color: white;
}

th,
td {
  text-align: center;
  vertical-align: middle;
}

/* Font Awesome Icon Styling */
th i {
  margin-left: 5px;
}

/* Pagination Styling */
.pagination {
  justify-content: center;
  margin-top: 20px;
}
