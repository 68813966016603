.page-container {
  padding: 20px;
  background-color: #f8f9fa; /* Light grey background */
}

.content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff; /* White background for content */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Light shadow for card-like appearance */
}

.content-center {
  text-align: center;
  color: #007bff; /* Custom color for heading */
  margin-bottom: 30px;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}
