.footer-custom {
  background: linear-gradient(
    45deg,
    #ff671f,
    #ffffff,
    #046a38
  ); /* Gradient from pink to purple */
  color: #06038d;
}

.footer-custom a {
  color: #06038d; /* White text color */
}

.footer-custom a:hover {
  color: #06038d; /* Light pink on hover */
}

.footer-custom p {
  margin-bottom: 0;
}
