.modal-body .text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Modify the carousel control button colors */
.carousel-control-prev,
.carousel-control-next {
  color: black; /* Change color to black */
}

/* Adjust the size of the carousel control buttons */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black; /* Optional: Add background for better visibility */
  border-radius: 50%; /* Optional: Make them circular */
}

/* Additional styles for the modal image */
.modal-body img {
  padding-top: 10px;
  max-height: 50vh; /* Ensure images fit within the viewport */
  object-fit: contain; /* Maintain aspect ratio */
}
/* Media query for mobile devices */
@media (max-width: 768px) {
  /* Adjust the max-width as needed for your definition of mobile */
  .modal-body img {
    padding-top: 80px; /* Set padding-top to 50px for mobile devices */
  }
}
/* Ensure the modal content is centered */
.modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-fullscreen {
  width: 100%;
  height: 100%;
  margin: 0;
}

.close-button {
  color: rgb(
    0,
    0,
    0
  ) !important; /* Change color to black for better visibility */
  font-size: 1rem !important; /* Increase size for better touch targets */
  background: rgba(
    255,
    255,
    255,
    0.4
  ) !important; /* White background with 40% transparency */
  border: none;
  cursor: pointer;
  z-index: 10; /* Ensure buttons are above other content */
  top: 20px;
  right: 20px;
}

/* Additional styles for the description scrollable area */
.description-scrollable {
  max-height: 400px; /* Limit height for better UX */
  overflow-y: auto; /* Enable scrolling */
  padding: 10px; /* Add padding */
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Slight background for readability */
  border-radius: 5px; /* Rounded corners */
}

.description-scrollable p {
  margin: 0; /* Remove default margin */
  color: black; /* Text color */
}
