/* Achiever Section */
.achiever-section {
  background-color: #007bff; /* Custom background color */
  color: white;
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
}

/* Achiever Card */
.achiever-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Team Section */
.team-section {
  background-color: #f8f9fa; /* Background color for KARYA KARINI section */
  padding-top: 80px;
  padding-bottom: 80px;
}

.team-card {
  border-radius: 10px;
  padding: 20px;
}

.team-card .card-title {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.team-card .card-subtitle {
  font-size: 1rem;
}

.team-section h2 {
  margin-bottom: 40px;
}

/* Keyframe for Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* General styles for carousel images */
.carousel-item img {
  width: 100%;
  height: auto;
}

/* Styles for larger images on mobile devices */
@media (max-width: 767px) {
  .carousel-item img {
    height: 70vh; /* Adjust the height as needed */
    object-fit: cover; /* Ensure the image covers the container */
  }
}
/* HomePage.css */

/* General styles for carousel images */
.carousel-item img {
  width: 100%;
  height: auto;
}

/* Styles for larger images on mobile devices */
@media (max-width: 767px) {
  .carousel-item img {
    height: 60vh; /* Adjust height as needed to fit mobile screens */
    object-fit: contain; /* Ensure the entire image is visible without cropping */
  }
}
/* HomePage.css */

/* General styles for carousel images */
.carousel-item img {
  width: 100%;
  height: auto;
}

/* Styles for larger images on mobile devices */
@media (max-width: 767px) {
  .carousel-item img {
    height: 60vh; /* Adjust height as needed to fit mobile screens */
    object-fit: contain; /* Ensure the entire image is visible without cropping */
  }
}

/* General Carousel Styles */
.carousel-item {
  position: relative;
  overflow: hidden;
}

/* Background Image Overlay */
.carousel-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../public/imgs/jatHostelBuilding.jpg") no-repeat center
    center;
  background-size: cover;
  filter: blur(10px); /* Adjust blur level as needed */
  z-index: -1; /* Ensure the blur effect is behind the image */
}

/* Image Styling */
.carousel-item img {
  width: 100%;
  height: auto;
  display: block; /* Ensures the image is displayed as a block element */
  z-index: 1; /* Ensures the image is above the blurred background */
}

/* HERE ON */

/* General Carousel Styles */
.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

/* Carousel Item Styles */
.carousel-item {
  position: relative;
  height: 100vh; /* Adjust as needed for the height of the carousel */
  display: flex;
  align-items: center; /* Center the image vertically */
  justify-content: center; /* Center the image horizontally */
}

/* Background Image Overlay */
.carousel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../public/imgs/jatHostelBuilding.jpg") no-repeat center
    center;
  background-size: cover;
  filter: blur(10px); /* Adjust blur level as needed */
  z-index: -1; /* Ensure the blur effect is behind the images */
}

/* Image Styling */
.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures image fits within the container without cropping */
  /* You can also use 'cover' if you prefer to fill the container */
}

/*  */

/* HomePage.css */

/* Facilities Section */
.facilities-section {
  overflow: hidden; /* Ensures no extra horizontal space is added */
}

.facility-slide {
  display: flex; /* Makes sure the slide content is properly aligned */
  justify-content: center; /* Centers the slide content */
}

.facility-card {
  margin: 0 auto; /* Centers the card within the slide */
  max-width: 100%; /* Prevents the card from exceeding its container width */
}

.facility-image {
  max-height: 200px; /* Adjust this value as needed */
  object-fit: cover; /* Ensures the image covers the area without distortion */
}
