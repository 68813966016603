/* Navbar Custom Styles */
.navbar-custom {
  background: linear-gradient(
    90deg,
    #ff671f,
    #ffffff,
    #046a38
  ); /* Gradient background */
}

.navbar-custom .navbar-brand {
  margin-left: 3px;
  font-size: 1.5rem; /* Larger font size */
  font-weight: bold; /* Bold font */
  color: #fff; /* White font color */
}

.navbar-custom .navbar-nav .nav-link {
  font-size: 1.1rem; /* Slightly larger font size */
  font-weight: 500; /* Medium weight font */
  color: #06038d; /* Font color */
}

.navbar-custom .navbar-nav .nav-link:hover {
  color: #e64b298c; /* Gold color on hover */
}

.navbar-custom .navbar-toggler-icon {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"%3E%3Cpath fill="white" d="M5 7h20v2H5zM5 13h20v2H5zM5 19h20v2H5z"/%3E%3C/svg%3E'); /* Custom SVG icon */
}

@media (max-width: 576px) {
  .navbar-custom .navbar-brand {
    margin-left: 3px;
    font-size: 1.5rem; /* Larger font size */
    font-weight: bold; /* Bold font */
    color: #030303; /* Dark font color */
  }
  .navbar-custom .navbar-nav .nav-link {
    margin-left: 2%;
    font-size: 1.1rem; /* Slightly larger font size */
    font-weight: 500; /* Medium weight font */
    color: #046a38; /* Font color */
  }
  .navbar-custom .navbar-nav .nav-link:hover {
    color: #e64b298c; /* Gold color on hover */
  }
}

/* flowing logo tricolor */
.flowing-text {
  font-size: 1.5rem; /* Adjust as needed */
  font-weight: bold;
  background: linear-gradient(90deg, #ff671f, #ffffff, #046a38);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  animation: gradient-flow 6s linear infinite;
  background-size: 300% 300%;
}

@keyframes gradient-flow {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

/*  */
