/* Ensure the body takes up the full height */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Ensure the page container takes at least full viewport height */
.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Content area to take up available space */
.content {
  flex: 1;
}

/* Ensure the footer is at the bottom */
footer {
  margin-top: auto;
}

/* HERE ON 1 */
/* App.css */
.table-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.table-wrapper table {
  width: 100%;
  min-width: 600px; /* Ensure the table is scrollable on smaller screens */
}

.table-wrapper th,
.table-wrapper td {
  padding: 0.75rem;
}

.table-wrapper .srno-column {
  width: 10%; /* Short width for SrNo column */
  font-size: 0.75rem; /* Smaller font size */
}

.table-wrapper .name-column,
.table-wrapper .father-name-column {
  width: 20%; /* Reduced width for Name and Father's Name columns */
}

.table-wrapper .address-column {
  width: 30%; /* Width for Address column */
}

.table-wrapper .amount-column {
  width: 20%; /* Width for Amount column */
}

@media (max-width: 768px) {
  .table-wrapper {
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }

  .table-wrapper th,
  .table-wrapper td {
    font-size: 0.875rem; /* Adjust font size for smaller screens */
  }

  .table-wrapper .srno-column {
    width: 10%; /* Adjust width for smaller screens */
    font-size: 0.7rem; /* Smaller font size for smaller screens */
  }

  .table-wrapper .name-column,
  .table-wrapper .father-name-column {
    width: 20%; /* Adjust width for smaller screens */
  }

  .table-wrapper .address-column {
    width: 20%; /* Adjust width for smaller screens */
  }

  .table-wrapper .amount-column {
    width: 20%; /* Adjust width for smaller screens */
  }
}
